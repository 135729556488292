import Vue from 'vue'
import VueRouter from 'vue-router'

Vue.use(VueRouter)

const routes = [{
        path: '/',
        name: 'home',
        redirect: '/index',
        component: () =>
            import ('@/views/index.vue'),
        children: [{
                path: '/index',
                component: () =>
                    import ('@/views/home/index.vue'),
            },
            {
                path: '/bid',
                name: 'bid',
                component: () =>
                    import ('@/views/bid/index.vue'),
                redirect: '/bid/list',
                children: [{
                        path: '/bid/list',
                        name: 'bidList',
                        component: () =>
                            import ('@/views/bid/bidList.vue')
                    },
                    {
                        path: '/bid/detail',
                        name: 'bidDetail',
                        component: () =>
                            import ('@/views/bid/detail.vue')
                    }
                ]
            },
            {
                path: '/auction',
                name: 'auction',
                component: () =>
                    import ('@/views/auction/index.vue'),
                redirect: '/auction/list',
                children: [{
                        path: '/auction/list',
                        name: 'auctionList',
                        component: () =>
                            import ('@/views/auction/list.vue')
                    },
                    {
                        path: '/auction/detail',
                        name: 'auctionDetail',
                        component: () =>
                            import ('@/views/auction/detail.vue')
                    }
                ]
            },
            {
                //公告
                path: '/announcement',
                name: 'announcement',
                component: () =>
                    import ('@/views/announcement/index.vue'),
                redirect: '/announcement/list',
                children: [{
                        path: '/announcement/list',
                        name: 'announcementList',
                        component: () =>
                            import ('@/views/announcement/list.vue')
                    },
                    {
                        path: '/announcement/detail',
                        name: 'announcementDetail',
                        component: () =>
                            import ('@/views/announcement/detail.vue')
                    }
                ]
            },
            {
                //帮助
                path: '/help',
                name: 'help',
                component: () =>
                    import ('@/views/help/index.vue'),
            },
            {
                path: '/personal',
                name: 'personal',
                component: () =>
                    import ('@/views/personal/index.vue'),
            },
            //personal
        ]
    },
    {
        path: '/login',
        name: 'login',
        component: () =>
            import ('@/views/login/login.vue')
    },
    {
        path: '/registerContent',
        name: 'registerContent',
        component: () =>
            import ('@/views/login/registerContent.vue')
    }
]

const router = new VueRouter({
    mode: 'hash',
    base: process.env.BASE_URL,
    routes
})

export default router