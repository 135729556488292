// const Config = {
//     apiUrl: 'http://8.130.109.52:8082'
// }
// export const apiUrl = 'http://8.130.109.52:8082'
export const apiUrl = 'http://www.jipaipt.com'
export const auctionStatus = [{
        id: 0,
        name: '未开始'
    },
    {
        id: 1,
        name: '即将开始'
    },
    {
        id: 2,
        name: '进行中（自由竞价阶段）'
    },
    {
        id: 3,
        name: '进行中（限时竞价阶段）'
    },
    {
        id: 4,
        name: '等待确认'
    },
    {
        id: 5,
        name: '暂停'
    },
    {
        id: 6,
        name: '终止'
    },
    {
        id: 9,
        name: '流标'
    },
    {
        id: 10,
        name: '成交'
    },
]
export const standardTypeList = [{
    id: 1,
    text: "机动车",
    name: "机动车"
}, {
    id: 2,
    text: "房产",
    name: "房产"
}, {
    id: 3,
    text: "工业循环物资",
    name: "工业循环物资"
}, {
    id: 4,
    text: "无形资产",
    name: "无形资产"
}, 
// {
//     id: 5,
//     text: "土地",
//     name: "土地"
// }, 
{
    id: 6,
    text: "文物艺术品",
    name: "文物艺术品"
}, {
    id: 7,
    text: "农副产品",
    name: "农副产品"
}, {
    id: 8,
    text: "股权债权",
    name: "股权债权"
}, {
    id: 9,
    text: "其他财产",
    name: "其他财产"
}, {
    id: 10,
    text: "其他交通工具",
    name: "其他交通工具"
}]

// export default {
//     apiUrl
// }