import Vue from 'vue'
import App from './App.vue'
import router from './router'
import store from './store'
Vue.prototype.bus = new Vue();
import api from "@/utils/api.js";
// import VueCompositionAPI from '@vue/composition-api'
// //注册使用@vue/composition-api插件
// Vue.use(VueCompositionAPI)
import "element-ui/lib/theme-chalk/index.css";
import ELEMENT from "element-ui";
import './assets/style/index.scss'
import "swiper/swiper.min.css"
import "../public/font/iconfont.css"
import { handleHttpResponse, handleHttpResponseSilent } from "./utils/http";
import { apiUrl } from "./config/config"
import { date } from "./utils/date"

Vue.use(ELEMENT);
Vue.prototype.$api = api;
Vue.prototype.$handleHttpResponse = handleHttpResponse;
Vue.prototype.$handleHttpResponseSilent = handleHttpResponseSilent;
Vue.prototype.$Config = apiUrl;
Vue.prototype.$date = date;

Vue.config.productionTip = false; //阻止启动生产消息

Array.prototype.remove = function(val) {
    var index = this.indexOf(val);
    if (index > -1) {
        this.splice(index, 1);
    }
};
Vue.use(ELEMENT);
Vue.config.productionTip = false

export default new Vue({
    router,
    store,
    render: h => h(App)
}).$mount('#app')