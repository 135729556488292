import Vue from "vue";
import Vuex from "vuex";

Vue.use(Vuex);

export default new Vuex.Store({
    state: {
        user: JSON.parse(localStorage.getItem("user")) || "",
        units: JSON.parse(localStorage.getItem("units")) || "",
        systemInfo: {}, //系统设置
        cityList: [], //  这个好像可以删掉
        flg: true,
        jurisdiction: JSON.parse(localStorage.getItem("jurisdiction")) || "0", // 权限
        managemen: 0, // 权限
        ctedNote: {}, //引注数据
        companyDetail: {}, //公司详情
    },
    mutations: {
        USER(state, a) {
            state.user = a;
        },
        CityList(state, a) {
            state.cityList = a;
        },
        FLG(state, a) {
            state.flg = a;
        },
        SETMANAGEMEN(state, a) {
            state.managemen = a;
        },
        SETJURISDICTION(state, a) {
            state.jurisdiction = a;
        },
        NOTE(state, a) {
            state.ctedNote = a;
        },
        SYSTEMINFO(state, a) {
            state.systemInfo = a;
        },
        COMPANYDETAIL(state, a) {
            state.companyDetail = a;
        },
    },
    actions: {
        user({ commit }, data) {
            commit("USER", data);
        },
        cityList({ commit }, data) {
            commit("CityList", data);
        },
        flg({ commit }, data) {
            commit("FLG", data);
        },
        getjurisdiction({ commit }, data) {
            commit("SETJURISDICTION", data);
        },
        getmanagemen({ commit }, data) {
            commit("SETMANAGEMEN", data);
        },
        getNote({ commit }, data) {
            commit("NOTE", data);
        },
        systemInfo({ commit }, data) {
            commit("SYSTEMINFO", data);
        },
        companyDetail({ commit }, data) {
            commit("COMPANYDETAIL", data);
        },
    },
    modules: {},
});