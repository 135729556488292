import { Message } from "element-ui";

// 默认处理http返回
function handleHttpResponse(res) {
    if (res.data.code === 0) {
        Message.success("操作成功");
        return Promise.resolve(res.data);
    } else {
        if (res.data.code !== 403) {
            Message.error(res.data.msg || ' 发生错误');
        }
        // 要不要返回错误呢?
        return Promise.reject(res.data);
    }
}

// 成功不报
function handleHttpResponseSilent(res) {
    if (res.data.code === 0) {
        return Promise.resolve(res.data);
    } else {
        // 报错了
        if (res.data.code !== 403) {
            Message.error(res.data.msg || ' 发生错误');
        }
        // 要不要返回错误呢?
        return Promise.reject(res.data);
    }
}

export {
    handleHttpResponse,
    handleHttpResponseSilent
}