import vue from "../main"
import axios from "axios";
import { Message } from "element-ui";
//ajax 默认设置
axios.defaults.timeout = 1000000;
axios.defaults.withCredentials = true; //  携带cookies
// axios.defaults.baseURL = "/api"; //本地
// axios.defaults.headers.post["Content-Type"] =
//     "application/x-www-form-urlencoded;charset=UTF-8";
axios.interceptors.request.use(
    config => {
        return config;
    },
    err => {
        return Promise.reject(err);
    }
);

// let isTell = false;

axios.interceptors.response.use(
    response => {
        const data = response.data;
        if (data && data.code === 401) {
            console.log(response, vue.$route, 'response')
                // 登录过期
            if (vue.$route.path != '/bid/detail') {
                vue.$router.push({ path: '/login' });
            }
            // vue.$router.push({ path: '/login' });
            // 假装成功了
            // if (!isTell) {
            //     isTell = true;
            //     setTimeout(() => {
            //         isTell = false;
            //     }, 1000);
            //     if (!response.config.url.includes("login")) {
            //         Message.info("您的登录已到期，请重新登录。")
            //     }
            // }
        }
        return response;
    },
    err => {
        Message.error(err.message || ' 发生错误');
        return Promise.reject(err.response);
    }
);
export default axios;