<template>
  <div id="app">
    <router-view/>
  </div>
</template>
<script>
export default {
  data(){
    return {
      cityList:[]
    }
  },
  mounted(){
    // this.themeEvent()
    this.getSystemInfo()
    this.getprovinceList()
  },
  methods:{
    themeEvent(){
      // let data=document.body 
    //   if(data.theme=="light"){
    //     data.theme="dark"
        //设置body的类名为theme-dark，使用dark文件中的样式
    //   }else{
    //     data.theme="light"
    //     document.body.className = `theme-light`
    //   }
    },
    getSystemInfo(){
      this.$api.systemInfo().then((res)=>{
        
        let data=res.data.data
        data.logos=this.$Config+data.logo.split('pass')[1]
        this.$store.dispatch("systemInfo", data);
        // console.log(data,'系统+++')
        document.body.className = `theme-${data.styleColor}` 
        // document.body.className = `theme-red` 
      //   this.$api.companyInfo({companyId:data.companyId}).then((res)=>{
      //     console.log(data,'公司')
      //     let companyDetail=res.data.data
      //     this.$store.dispatch("companyDetail", companyDetail);
      // })
      })
      
    },
        //获取城市列表
        async getprovinceList(){
           await this.$api.provinceList().then((res)=>{
                let data=res.data.data
                data.map(async (item)=>{
                    item.children=await this.getcityList(item.id)
                    this.cityList.push({...item,label:item.name,value:item.id})
                })
                
            })
            this.$store.dispatch("cityList", this.cityList);
        },
        //获取市列表
        getcityList(provinceId){
           return this.$api.cityList({provinceId}).then((res)=>{
               let  data=res.data.data.map((item)=>{
                return{
                    ...item,
                    label:item.name,value:item.id
                }
               })
               return [...data]
            })
        },

  }
}
</script>
<style lang="scss">
@import '@/assets/style/global.scss';
html,body,#app{
  width: 100%;
  height: 100%;
  overflow: hidden;
  margin: 0;
  background: #f6f6f6;
  
}
#app {
  color: #333;
  font-size: calc(0.16rem);
}

nav {
  padding: 30px;

  a {
    font-weight: bold;
    color: #2c3e50;

    &.router-link-exact-active {
      color: #42b983;
    }
  }
}
</style>
