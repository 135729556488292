import axios from "./request.js";
// import qs from "qs";
// // import axios from "axios"
// import service from "axios";

const api = {
    // ,{
    //   headers: {
    //     "Content-Type": "application/x-www-form-urlencoded"
    //   },
    // }
    //系统
    ///api/c/singleDomain/get
    systemInfo(params) {
        return axios.get(`/api/c/singleDomain/get`, { params });
    },
    //公司
    companyInfo(params) {
        return axios.get(`/api/c/company/get`, { params });
    },
    //帮助
    //帮助树状图
    helpList(params) {
        return axios.get(`/api/c/helpDoc/tree`, { params });
    },
    //帮助详情
    helpDetail(params) {
        return axios.get(`/api/c/helpDoc/get`, { params });
    },
    //banner
    bannerList(params) {
        return axios.get(`/api/c/mainPageBanner/query`, { params });
    },
    //上传图片
    update(params) {
        return axios.post("/api/clientUploadFle/uploadImage", params);
    },
    //拍卖登记
    auctionRegistration(params) {
        return axios.post("/api/m/assetAuction/save", params);
    },
    //需求登记
    demandRegistration(params) {
        return axios.post("/api/m/assetDemand/save", params);
    },
    //拍卖会列表
    auctionList(params) {
        return axios.get(`/api/c/meet/query/simple`, { params });
    },
    //拍卖会详情
    auctionDetail(params) {
        return axios.get(`/api/c/meet/get`, { params });
    },
    //拍卖会标的列表
    auctionBidList(params) {
        return axios.get(`/api/c/meet/lot`, { params });
    },
    //标的列表
    bidList(params) {
        return axios.get(`/api/c/clot/query/simple`, { params });
    },
    //标的详情
    bidDetail(params) {
        return axios.get(`/api/c/clot/get`, { params });
    },
    //标的详情（循环获取标的状态可用）
    bidMessage(params) {
        return axios.get(`/api/c/clot/get/simple`, { params });
    },
    //检查是否需要刷新数据
    isUpdate(params) {
        return axios.get(`/api/t/lot/check`, { params });
    },
    //出价记录
    record(params) {
        return axios.get(`/api/c/lotPriceLog/query`, { params });
    },
    //拍卖师发言
    statement(params) {
        return axios.get(`/api/c/speakLog/query`, { params });
    },
    //拍卖师信息
    auctioneer(params) {
        return axios.get(`/api/c/auctioneer/get`, { params });
    },
    //出价
    offerAPrice(params) {
        return axios.post(`/api/c/lotPriceLog/bidPrice`, params);
    },
    //报名
    apply(params) {
        return axios.post(`/api/c/buyerMeet/buyerSignupMeet`, params);
    },
    //查询报名审核情况
    applyAudit(params) {
        return axios.get(`/api/c/buyerMeet/queryBuyerMeet`, { params });
    },
    //查询实名认证信息
    autonymInfo(params) {
        return axios.get(`/api/c/buyer/get`, { params });
    },

    //关注标的
    attentionBid(params) {
        return axios.post(`/api/c/userAttentionLot/save`, params);
    },
    //取消关注标的
    cancelAttentionBid(params) {
        return axios.delete(`/api/c/userAttentionLot/del`, { data: params });
    },
    //关注列表
    attentionBidList(params) {
        return axios.get(`/api/c/userAttentionLot/query/simple`, { params });
    },
    //我的参拍
    participationList(params) {
        return axios.get(`/api/c/buyerMeet/myJoinMeet`, { params });
    },
    //公告
    //公告列表
    announcementList(params) {
        return axios.get(`/api/c/notice/query/simple`, { params });
    },
    //公告详情
    announcementDetail(params) {
        return axios.get(`/api/c/notice/get`, { params });
    },
    //公告所含拍卖会
    announcementAuction(params) {
        return axios.get(`/api/c/notice/meet`, { params });
    },

    //公用
    //省
    provinceList(params) {
        return axios.get(`/api/dim/province`, { params });
    },
    //城市
    cityList(params) {
        return axios.get(`/api/dim/city`, { params });
    },
    //县
    countyList(params) {
        return axios.get(`/api/dim/county`, { params });
    },
    // 登录
    tologin(params) {
        return axios.post("/api/sec/login", params);
    },
    //注册
    register(params) {
        return axios.post("/api/c/user/save", params);
    },
    //找回密码
    resetPw(params) {
        return axios.post("/api/c/user/resetPw", params);
    },
    //实名认证
    attention(params) {
        return axios.post("/api/c/buyer/save", params);
    },
    //验证码
    // verification({ phone = "" }) {
    //     return axios.get(`/api/sms?phone=${phone}`);
    // },
    verification({ phone = "", type = 'verificationCode' }) {
        return axios.get(`/api/sms/verificationCode?mobile=${phone}&type=${type}`);
    },
    //退出登录
    logOut() {
        return axios.get(`/api/sec/logout`);
    },
    //查询用户信息
    getUserInfo() {
        return axios.get(`/api/sec/principal`);
    },





    //注销登录
    writeOff(userId) {
        return axios.delete(`/api/auth/user/${userId}`);
    },
    //搜索期刊
    searchPeriodical(params) {
        return axios.post("/api/periodical", params);
    },
    //期刊对比
    periodicalComparison(params) {
        return axios.post(`/api/periodicalContrast`, params);
    },
    //根据期刊id，获取期刊详情列表
    perDetail({ id }) {
        return axios.get(`/api/periodical/${id}`);
    },
    //生成报告
    setPdf({ productId, queryId }) {
        return axios.get(
            `/api/exportPdf?productId=${productId}&queryId=${queryId}`
        );
    },
    //根据用户id,获取用户信息
    // /user
    getUser({ id }) {
        return axios.get(`/api/user?id=${id}`);
    },
    //报告列表
    getReport({ page, rows, userId }) {
        return axios.get(
            `/api/generateFile?page=${page}&rows=${rows}&userId=${userId}`
        );
    },
    //选刊列表
    fetchGanList({ pageIndex, pageSize, userId }) {
        return axios.get(
            `/api/queryInformation?pageIndex=${pageIndex}&pageSize=${pageSize}&userId=${userId}`
        );
    },
    //消息列表
    getMessage({ page, rows, userId }) {
        return axios.get(`/api/message/${userId}?page=${page}&rows=${rows}`);
    },
    //修改密码
    changePassword(data) {
        return axios.put(`/api/auth/password`, data);
    },
    //
    citedExtract(data) {
        return axios.post(`/api/citedExtract`, data);
    },
    //transformation
    transformation(data) {
        return axios.post(`/api/transformation`, data);
    },

    //下载PDF文件
    // downFile(){
    //   return axios.get(`/api/generateFile/download`)
    // }
    // /paper/citedExtract
    // 引注抽取
    injectionExtractionApi(data) {
        return axios.post(`/api/citedExtract`, data);
    },
    // 获取某用户所有引注查询信息
    getPageCitedNote({ pageIndex, pageSize, userId }) {
        return axios.get(`/api/pageCitedNote?pageIndex=${pageIndex}&pageSize=${pageSize}&userId=${userId}`);
    },
    // 获取单条引注信息
    getPageCitedNoteItem(id) {
        return axios.get(`/api/citedNote/${id}`);
    },
    // 获取生成报告详情
    fetchReportDetail(productId, queryId) {
        return axios.get(
            `/api/generateReport?productId=${productId}&queryId=${queryId}`
        );
    },
};

export default api;